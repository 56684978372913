class window.Catalog
  constructor: ->
    @_$teasers = $('.catalog__teasers')
    @_$teasersThumb = $('.catalog__teasers__item', @_$teasers)
    @_$tabsSlider = $('.tabs-slider')
    @_$productLink = $("a[data-event='product-show']", @_$teasersThumb)
    @_$formFilterCheckbox = $('.filter-stock')
    @_offset = 0

    @_init()

  _init: ->
    @_$teasers.mCustomScrollbar
      scrollButtons:
        enable: true

    if @_$productLink.length
      @_product = new window.Product()

    @_$formFilterCheckbox.each (i, el) =>
      that = $(el)
      @_filter(that)

    @_showProduct(@_$productLink.first())

    @_events()

    @_openTab()

  _events: ->
    @_$productLink.on 'click', (e) =>
      e.preventDefault()

      @_showProduct($(e.currentTarget))

    @_$formFilterCheckbox.on 'change', (e) =>
      that = $(e.currentTarget)
      @_filter(that)

    $(document).on 'change.zf.tabs', (e) =>
      $active = $(e.target).find('.tabs-title.is-active')
      if $active.position()
        @_offset = $active.position().left
      @_$tabsSlider.css 'left', @_offset

  _showProduct: (link)->
    if link.siblings('.data-html').length
      @_$teasersThumb.removeClass 'active'
      link.parent().addClass 'active'

      @_product.show link.siblings '.data-html'

  _filter: (el) ->
    products = el.parents('.tabs-panel').find('.catalog__teasers__item:not([data-available])')

    if el.is(':checked')
      products.hide()
    else
      products.show()

  _openTab: ->
    if t = @_getUrlParameter('t')
      $('ul.tabs li a').each () ->
        hash = $(this).attr('href').split('#')[1]
        if hash == t
          $(this).click()
    else
      $('ul.tabs li:first-child a').click()

    window.history.pushState("", "", "/")

  _getUrlParameter: (sParam) ->
    sPageURL = decodeURIComponent(window.location.search.substring(1))
    sURLVariables = sPageURL.split('&')

    for i in [0...sURLVariables.length]
      sParameterName = sURLVariables[i].split('=')

      if sParameterName[0] == sParam
        return if sParameterName[1] == undefined then true else sParameterName[1]